<template>
  <my-dv-page
      class="user"
      id="body"
      :width="1920"
      :height="1080"
      target="#app"
      :fullscreen="true">
      <my-dv-box fit class="app-index" :class="{bg3:isbg3}">
        <Header></Header>
        <router-view/>
      </my-dv-box>
    <my-dv-box right="28px" z-index="11111"  v-if="backUrl"  top="70px" width="24px" height="24px" class="npo">
      <el-image @click="gotoBack" :src="require('@/assets/images/矢量智能对象拷贝.png')" style="width: 24px;height: 24px;cursor:pointer;"></el-image>
    </my-dv-box>
  </my-dv-page>
</template>

<script>
import router from "@/router";
import Header from "@/views/Header";
export default {
  name: "index1",
  components:{Header},
  computed:{
    isbg3(){
      return this.$route.name==='xinbeiqu-ambient'
    },
    backUrl(){
      return this.$route.query?.back?this.$route.query.back:(this.$route.meta?.backurl?this.$route.meta.backurl:'')
    },
  },
  watch:{
    '$route'(){
      this.$store.commit('setfarmId',this.$route.meta.farmId)
    },
  },
  created() {
    this.$nextTick(_=>{
      this.$el.setAttribute('id','app-index')
    })
  },
  beforeRouteUpdate(to, from, next){
    console.log(this.$route)
    next()
  },
  beforeCreate() {
    this.$store.commit('setfarmId',this.$route.meta.farmId)
  },
  methods: {
    gotoBack (){
      this.$router.push(this.backUrl)
    }
  }
};
</script>

<style scoped lang="scss">
.app-index{
  background:url("~@/assets/images/background2.png") no-repeat;
  background-size: 100% 100%;
}
.bg3{
  background:url("~@/assets/images/background3.png") no-repeat;
  background-size: 100% 100%;
}
</style>
